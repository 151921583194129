import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { truncateText } from "../../utils/helper";

function CustomTooltip(args) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        <div id="TooltipContent">{truncateText(args?.value)}</div>
      </p>
      <Tooltip
        {...args}
        isOpen={tooltipOpen}
        target="TooltipContent"
        toggle={toggle}
        style={{ fontSize: "12px" }}
      >
        {args?.content}
      </Tooltip>
    </div>
  );
}

CustomTooltip.args = {
  autohide: true,
  flip: true,
};

CustomTooltip.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["top", "left", "right", "bottom"],
  },
};

export default CustomTooltip;
